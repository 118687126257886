import { useEffect, useState } from "react";
import Web3 from "web3";
import contract from "../contracts/contract.json";

import bg from "../assets/back.png";
import logoo from "../assets/logo.png";
import discord from "../assets/discord.png";
import twitter from "../assets/twitter.png";
import insta from "../assets/insta.png";
import toadzz from "../assets/gif1.png";
import toadzz2 from "../assets/gif2.png";
import toadzz3 from "../assets/gif3.png";
import toadzz4 from "../assets/gif4.png";
import stbg from "../assets/stbg.png";
import logo2 from "../assets/logo2.png";
import me from "../assets/maingif.gif";
import scroll2 from "../assets/scroll2.png";
import Zoom from 'react-reveal/Zoom';
import love from "../assets/love.gif";

const initialInfoState = {
  connected: false,
  status: null,
  account: null,
  web3: null,
  contract: null,
  address: null,
  contractJSON: null,
};

const initialMintState = {
  loading: false,
  status: `Mint your ${contract.name}`,
  amount: 1,
  supply: "0",
  cost: "0",
};

function Minter() {
  const [info, setInfo] = useState(initialInfoState);
  const [mintInfo, setMintInfo] = useState(initialMintState);

  console.log(info);

  const init = async (_request, _contractJSON) => {
    if (window.ethereum.isMetaMask) {
      try {
        const accounts = await window.ethereum.request({
          method: _request,
        });
        const networkId = await window.ethereum.request({
          method: "net_version",
        });
        if (networkId == _contractJSON.chain_id) {
          let web3 = new Web3(window.ethereum);
          setInfo((prevState) => ({
            ...prevState,
            connected: true,
            status: null,
            account: accounts[0],
            web3: web3,
            contract: new web3.eth.Contract(
              _contractJSON.abi,
              _contractJSON.address
            ),
            contractJSON: _contractJSON,
          }));
        } else {
          setInfo(() => ({
            ...initialInfoState,
            status: `Change network to ${_contractJSON.chain}.`,
          }));
        }
      } catch (err) {
        console.log(err.message);
        setInfo(() => ({
          ...initialInfoState,
        }));
      }
    } else {
      setInfo(() => ({
        ...initialInfoState,
        status: "Please install metamask.",
      }));
    }
  };

  const initListeners = () => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", () => {
        window.location.reload();
      });
      window.ethereum.on("chainChanged", () => {
        window.location.reload();
      });
    }
  };

  const getSupply = async () => {
    const params = {
      to: info.contractJSON.address,
      from: info.account,
      data: info.contract.methods.totalSupply().encodeABI(),
    };
    try {
      const result = await window.ethereum.request({
        method: "eth_call",
        params: [params],
      });
      console.log(info.web3.utils.hexToNumberString(result));
      setMintInfo((prevState) => ({
        ...prevState,
        supply: info.web3.utils.hexToNumberString(result),
      }));
    } catch (err) {
      setMintInfo((prevState) => ({
        ...prevState,
        supply: 0,
      }));
    }
  };

  const getCost = async () => {
    const params = {
      to: info.contractJSON.address,
      from: info.account,
      data: info.contract.methods.PRICE().encodeABI(),
    };
    try {
      const result = await window.ethereum.request({
        method: "eth_call",
        params: [params],
      });
      console.log(info.web3.utils.hexToNumberString(result));
      setMintInfo((prevState) => ({
        ...prevState,
        cost: info.web3.utils.hexToNumberString(result),
      }));
    } catch (err) {
      setMintInfo((prevState) => ({
        ...prevState,
        cost: "0",
      }));
    }
  };

  const mint = async () => {
    const params = {
      to: info.contractJSON.address,
      from: info.account,
      value: String(
        info.web3.utils.toHex(Number(mintInfo.cost) * mintInfo.amount)
      ),
      data: info.contract.methods
        .mintToad(mintInfo.amount)
        .encodeABI(),
    };
    try {
      setMintInfo((prevState) => ({
        ...prevState,
        loading: true,
        status: `Minting ${mintInfo.amount}...`,
      }));
      const txHash = await window.ethereum.request({
        method: "eth_sendTransaction",
        params: [params],
      });
      setMintInfo((prevState) => ({
        ...prevState,
        loading: false,
        status:
          "Nice! Your NFT will show up on Marketplaces, once the transaction is successful.",
      }));
      getSupply();
    } catch (err) {
      setMintInfo((prevState) => ({
        ...prevState,
        loading: false,
        status: err.message,
      }));
    }
  };

  const updateAmount = (newAmount) => {
    if (newAmount <= 21 && newAmount >= 1) {
      setMintInfo((prevState) => ({
        ...prevState,
        amount: newAmount,
      }));
    }
  };

  const connectToContract = (_contractJSON) => {
    init("eth_requestAccounts", _contractJSON);
  };

  useEffect(() => {
    connectToContract(contract);
    initListeners();
  }, []);

  useEffect(() => {
    if (info.connected) {
      getSupply();
      getCost();
    }
  }, [info.connected]);

  function myFunction() {
    var x = document.getElementById("myTopnav");
    if (x.className === "mmenu") {
      x.className += " responsive";
    } else {
      x.className = "mmenu";
    }
  }

  return (
<div className="main">

<div id="top123" className="page">
    <div className="heading">
     <img className="logo" src={logoo} />
      <div className="mmenu" id="myTopnav">
       <a href="javascript:void(0);" style={{ color: "#86e772" }} className="icon" onClick={myFunction}>
         <i class="fa fa-bars"></i>
       </a>
       <a className="minted" href="#story">About</a>
       <a className="minted" href="#vision">Vision</a>
       <a className="insta" target="_blank" href="https://explorer.zksync.io/address/0x5ac8c62d1d9aa8eb69df3808033adb3adf578f7e#contract">
        <img className="social" src={insta} />
       </a>
       <a className="social" target="_blank" href="https://twitter.com/zkSyncToadz">
        <img className="social" src={twitter} />
       </a>

       <a className="social" target="_blank" href="https://discord.gg/c3KduGYXPU">
        <img className="social" src={discord} />
       </a>
      </div>
    </div>
      <div className="bgd">
       <img className="back" alt={"banner"} src={bg} />
      </div>
    <div className="card">
        <div className="card_header colorGradient">
          <img className="card_header_image ns" alt={"banner"} src={me} />
        </div>
        {mintInfo.supply < contract.total_supply ? (
          <div className="card_body">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                disabled={!info.connected || mintInfo.cost == "0"}
                className="small_button"
                onClick={() => updateAmount(mintInfo.amount - 1)}
              >
                -
              </button>
              <div style={{ width: 10 }}></div>
              <button
                disabled={!info.connected || mintInfo.cost == "0"}
                className="button"
                onClick={() => mint()}
              >
                Mint {mintInfo.amount}
              </button>
              <div style={{ width: 10 }}></div>
              <button
                disabled={!info.connected || mintInfo.cost == "0"}
                className="small_button"
                onClick={() => updateAmount(mintInfo.amount + 1)}
              >
                +
              </button>
            </div>
            {info.connected ? (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ color: "var(--statusText)", textAlign: "center" }}>
                  {info.web3?.utils.fromWei(mintInfo.cost, "ether") *
                    mintInfo.amount}{""}
                  {contract.chain_symbol}
                </p>
                <div style={{ width: 20 }}></div>
                <p style={{ color: "var(--statusText)", textAlign: "center" }}>
                  |
                </p>
                <div style={{ width: 20 }}></div>
                <p style={{ color: "var(--statusText)", textAlign: "center" }}>
                  {mintInfo.supply}/{contract.total_supply}
                </p>
              </div>
            ) : null}
            {mintInfo.status ? (
              <p className="statusText">{mintInfo.status}</p>
            ) : null}
            {info.status ? (
              <p className="statusText" style={{ color: "var(--error)" }}>
                {info.status}
              </p>
            ) : null}
          </div>
        ) : (
          <div className="card_body">
            <p style={{ color: "var(--statusText)", textAlign: "center" }}>
              {mintInfo.supply}/{contract.total_supply}
            </p>
            <p className="statusText">
              We've sold out! .You can still buy and trade the {contract.name}{" "}
              on marketplaces.
            </p>
          </div>
        )}
        <div className="card_footer colorGradient">
          <button
            className="button"
            style={{
              backgroundColor: info.connected
                ? "var(--success)"
                : "var(--warning)",
            }}
            onClick={() => connectToContract(contract)}
          >
            {info.account ? "Connected" : "Connect Wallet"}
          </button>
          {info.connected ? (
            <span className="accountText">
              {String(info.account).substring(0, 6) +
                "..." +
                String(info.account).substring(38)}
            </span>
          ) : null}
        </div>
        <a
          style={{
            position: "absolute",
            bottom: 65,
            left: -75,
          }}
          className="_90"
          target="_blank"
          href="https://explorer.zksync.io/address/0x5ac8c62d1d9aa8eb69df3808033adb3adf578f7e#contract"
        >
          View Contract
        </a>
      </div>
</div>


<div id="story" className="about">

    <div className="amin">
     <div className="asmall">
        <div className="bbb">
         <p className="story" style={{ fontSize: " 40px" }}>&nbsp; &nbsp;About</p>
         <p style={{ position: "relative", fontWeight: "normal" }}>zkToadz is a collection of 6969 small amphibious creatures living on zkSync ERA network.</p>
         <p style={{ position: "relative", fontWeight: "normal" }}>Network: zkSync ERA</p>
         <p style={{ position: "relative", fontWeight: "normal" }}>Total supply: 6969</p>
         <p style={{ position: "relative", fontWeight: "normal" }}>Mint price: 0.003</p>
         <p style={{ position: "relative", fontWeight: "normal" }}>TOADZ ARE ON A MISSION TO BUILD THE MOST SOCIALLY ENGAGED, AND COLLABORATIVE COMMUNITY in all ZK Worlds.</p>
         <p style={{ position: "relative", fontWeight: "normal" }}>TOAD-TASTIC!</p>
        </div>
     </div>
     <Zoom>
      <div className="abig2">
        <img className="toad1" src={toadzz} />
        <img className="toad1" src={toadzz3} />
        <img className="toad1" src={toadzz2} />
        <img className="toad1" src={toadzz4} />
      </div>
      </Zoom>
     <img className="stbg" src={stbg} />
    </div>

</div>

  <div id="vision" className="design">
    <h2 className="info">
      <p style={{ fontSize: " 40px" }}>Vision</p>
    </h2>
      <div className="team">
        <p style={{ margin: "auto", fontWeight: "normal" }}>Break the Seal, Open the Scroll</p>
        <p style={{ margin: "auto", fontWeight: "normal" }}>Learn secret wisdom of the Toadz.</p>
        <a target="_blank" href="https://www.youtube.com/watch?v=jT_lwrjQNFw"> <img className="teampic" src={scroll2} /></a>
      </div>
      <h2 className="info">
      <p style={{ fontSize: " 40px", marginBottom: "0", marginTop:"2em"}}>Join The SWAMP</p>
      </h2>
    <div className="dmin">
      <div className="dsmall">
        <p style={{ fontWeight: "normal" }}>Chill, Flex and find ALPHA. !croak </p>
        <button class="button1">
        <a  style={{ color: "white", textDecoration: "none" }} target="_blank" href="https://discord.gg/c3KduGYXPU">DISCORD</a>
        </button>
      </div>
      <div className="dmid">
        <img className="cm" src={love} />
      </div>
    </div>
  </div>


<div className="footer">
  <Zoom>
    <div className="footerin animated animatedFadeInUp fadeInUp">
      <a className="info" target="_blank" href="#top123">
        <img className="footerpic" src={logo2} />
      </a>
      <div className="footermenu">
        <a className="fmenu" target="_blank" href="https://mintsquare.io/collection/zksync/0x5ac8c62d1d9aa8eb69df3808033adb3adf578f7e/"> &nbsp;Marketplace &nbsp; </a>
        <a className="fmenu" target="_blank" href="https://twitter.com/zkSyncToadz"> &nbsp;Twitter &nbsp; </a>
        <a className="fmenu" target="_blank" href="https://discord.gg/c3KduGYXPU"> &nbsp;Discord &nbsp; </a>
      </div>
      <p style={{ color: "#86e772", fontWeight: " normal" }}>zkSync TOADZ 2023, Toad-gether Stronger.</p>
    </div>
  </Zoom>
</div>
</div>
  );
}

export default Minter;
